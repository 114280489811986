import { DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';

import { DIALOG_MAX_WIDTH } from './dialog-sizes';

export const ARTICLE_DIALOG_CONFIG: DialogConfig<
  unknown,
  DialogRef<any, any>
> = {
  autoFocus: false,
  backdropClass: '.overlay-backdrop',
  hasBackdrop: false,
  maxHeight: '100vh',
  maxWidth: '100vw',
  panelClass: 'article-popup',
  positionStrategy: new GlobalPositionStrategy()
    .left('4.5625rem')
    .top('4.5rem'),
};

export const COOKIE_DIALOG_CONFIG: DialogConfig<
  unknown,
  DialogRef<any, any>
> = {
  autoFocus: '__non_existing_element__',
  maxWidth: '60.75rem',
  panelClass: 'cookie',
  positionStrategy: new GlobalPositionStrategy()
    .centerHorizontally()
    .top('8.5rem'),
  width: 'calc(100% - 3rem)',
};

export const COOKIE_OVERLAY_CONFIG: DialogConfig<
  unknown,
  DialogRef<any, any>
> = {
  autoFocus: '__non_existing_element__',
  hasBackdrop: false,
  maxWidth: '41rem',
  panelClass: 'cookie',
  positionStrategy: new GlobalPositionStrategy()
    .bottom('1.5rem')
    .right('1.5rem'),
  width: 'calc(100% - 3rem)',
};

export const IN_PROGRESS_DIALOG_CONFIG: DialogConfig<
  unknown,
  DialogRef<any, any>
> = {
  autoFocus: false,
  maxWidth: DIALOG_MAX_WIDTH,
  panelClass: 'in-progress-popup',
};
