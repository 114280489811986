<app-close-button class="modal" (click)="closeDialog()"></app-close-button>

<div class="header">
  <h3 i18n class="t-heading-3 t-bold">Просмотр модели недоступен</h3>

  <p i18n class="t-subtitle subtitle">
    Оформите подписку, чтобы получить доступ ко всем моделям каталога
  </p>
</div>

<div class="category-card">
  <div class="category-card__info">
    <span i18n class="t-heading-5 t-semi-bold">PRO Подписка</span>

    <ul class="features t-medium">
      <li i18n class="feature">Полный доступ к 3D моделям</li>
      <li i18n class="feature">Все функциональные возможности</li>
      <li i18n class="feature">Регулярные обновления</li>
    </ul>

    <div class="prices">
      @for (price of (userSubsSelector | async)?.[0].product; track $index) {
        <div class="price">
          <span class="price__value"
            >{{
              (price?.price / 100).toFixed(2) | currency: price.currencyCode
            }}&nbsp;</span
          >

          <span i18n class="t-caption price__period">
            /&nbsp;
            {price.periodUnit | lowercase, select,
              year {ежегодно}
              month {ежемесячно}
            }
          </span>
        </div>
      }
    </div>
  </div>

  <img
    class="category-card__image"
    src="assets/img/monetisation/pro-sub.png"
    alt="" />
</div>

<button i18n appButton class="subscribe" (click)="redirectToSubscription()">
  Перейти к оформлению
</button>
