import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUserInfo } from '@store/user/user.selectors';
import { Observable, map, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private store: Store) {}

  public checkAuthentication$(): Observable<boolean> {
    return this.store.select(selectUserInfo).pipe(take(1), map(Boolean));
  }
}
