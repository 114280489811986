import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appImageNotFound]',
  standalone: true,
})
export class ImageNotFoundDirective {
  @HostListener('error', ['$event']) public onerror(event: any) {
    event.target.src = '/assets/img/card-stub.png';
  }
}
