import { DialogRef } from '@angular/cdk/dialog';
import {
  AsyncPipe,
  CurrencyPipe,
  JsonPipe,
  LowerCasePipe,
} from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUserSubscriptions } from '@store/user/user.selectors';

import { ROUTING_MAP } from '../../models/constants/routing-map';
import { CloseButtonComponent } from '../close-button/close-button.component';

@Component({
  selector: 'app-purchase-unavailable-item',
  standalone: true,
  imports: [
    AsyncPipe,
    CloseButtonComponent,
    CurrencyPipe,
    JsonPipe,
    LowerCasePipe,
  ],
  templateUrl: './purchase-unavailable-item.component.html',
  styleUrls: ['./purchase-unavailable-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseUnavailableItemComponent {
  public userSubsSelector = this.store.select(selectUserSubscriptions);

  public routingMap = ROUTING_MAP;

  constructor(
    private store: Store,
    private dialogRef: DialogRef,
    private router: Router
  ) {}

  public redirectToSubscription(): void {
    this.router.navigate([
      this.routingMap.profile.index,
      this.routingMap.profile.subscription,
    ]);

    this.closeDialog();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
