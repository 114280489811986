<div class="wrapper">
  @if (itemInCollection) {
    <button
      class="button-wrapper"
      [disabled]="buttonDisabled"
      (click)="removeFromCollection($event)">
      <svg-icon class="star star-selected" [key]="markIconSrc"></svg-icon>
    </button>
  } @else {
    <button
      class="button-wrapper"
      [disabled]="buttonDisabled"
      (click)="addToCollection($event)">
      <svg-icon class="star star-unselected" [key]="unmarkIconSrc"></svg-icon>
    </button>
  }
</div>
