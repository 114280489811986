import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  effect,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SvgIconComponent, SvgIcons } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';
import { selectUserInfo } from '@store/user/user.selectors';
import {
  EMPTY,
  catchError,
  finalize,
  iif,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { CollectionSection } from '@app/pages/collection/models/collection-section.type';
import { CollectionApiService } from '@app/shared/api/collection/collection-api.service';
import { CollectionUuids } from '@app/signal-store/collection/collection-state.interface';
import { CollectionStore } from '@app/signal-store/collection/collection.store';
import { AlertStatus } from '../../models/enums/alert-status.enum';
import { ICollectionItem } from '../../models/interfaces/collection-item.interface';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { AuthComponent } from '../auth/auth.component';

@Component({
  selector: 'app-collection-status',
  templateUrl: './collection-status.component.html',
  styleUrls: ['./collection-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SvgIconComponent],
})
export class CollectionStatusComponent implements OnChanges, OnInit {
  @Input() public collectionItem: ICollectionItem;

  @Input() public markIconSrc: SvgIcons = 'star-selected';

  @Input() public unmarkIconSrc: SvgIcons = 'star-unselected';

  @Output() public collectionStatusUpdating = new EventEmitter<boolean>();

  @Output() public collectionStatusUpdated = new EventEmitter<void>();

  public itemInCollection: boolean;

  public buttonDisabled: boolean;

  constructor(
    private readonly collectionStore: CollectionStore,
    private alertService: AlertService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private collectionApiService: CollectionApiService,
    private destroyRef: DestroyRef,
    private dialog: Dialog,
    private injector: Injector,
    private store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['collectionItem'].currentValue) {
      effect(
        () => {
          const collectionUuids = this.collectionStore.collectionUuids();
          this.itemInCollection = this.checkIfItemInCollection(
            this.collectionItem.uuid,
            this.collectionItem.type,
            collectionUuids
          );
          this.cdr.markForCheck();
        },
        { injector: this.injector }
      );
    }
  }

  public ngOnInit(): void {
    this.observeUserLogged();
  }

  public addToCollection(event: Event): void {
    this.buttonDisabled = true;
    this.collectionStatusUpdating.emit(this.buttonDisabled);

    event.stopPropagation();

    this.authService
      .checkAuthentication$()
      .pipe(
        take(1),
        switchMap(userAuthenticated =>
          iif(
            () => userAuthenticated,
            this.collectionApiService
              .addItem$(this.collectionItem.uuid, this.collectionItem.type)
              .pipe(
                catchError(() => {
                  this.alertService.show({
                    message: $localize`Не получилось добавить в коллекцию`,
                  });
                  return EMPTY;
                }),
                tap(() => {
                  this.collectionStore.loadCollection();
                  this.collectionStore.loadCollectionUuids();
                }),
                finalize(() => {
                  this.buttonDisabled = false;
                  this.collectionStatusUpdating.emit(this.buttonDisabled);
                }),
                takeUntilDestroyed(this.destroyRef)
              ),
            of(true)
          )
        )
      )
      .subscribe(isUnauthorized => {
        if (isUnauthorized) {
          this.dialog.open(AuthComponent);

          this.buttonDisabled = false;
        } else {
          this.updateCollectionStatus();
          this.alertService.show({
            message: $localize`Успешно добавлено в коллекцию`,
            status: AlertStatus.Success,
          });
        }
      });
  }

  public removeFromCollection(event: Event): void {
    this.buttonDisabled = true;
    this.collectionStatusUpdating.emit(this.buttonDisabled);

    event.stopPropagation();

    this.collectionApiService
      .deleteItem$(this.collectionItem.uuid, this.collectionItem.type)
      .pipe(
        catchError(() => {
          this.alertService.show({
            message: $localize`Не получилось удалить из коллекции`,
          });
          return EMPTY;
        }),
        tap(() => {
          this.collectionStore.loadCollection();
          this.collectionStore.loadCollectionUuids();
        }),
        finalize(() => {
          this.buttonDisabled = false;
          this.collectionStatusUpdating.emit(this.buttonDisabled);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.updateCollectionStatus();
        this.alertService.show({
          message: $localize`Успешно удалено из коллекции`,
          status: AlertStatus.Success,
        });
      });
    return;
  }

  private updateCollectionStatus(): void {
    this.collectionStatusUpdated.emit();
    this.cdr.markForCheck();
  }

  private observeUserLogged(): void {
    this.store
      .select(selectUserInfo)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        this.itemInCollection = user
          ? this.checkIfItemInCollection(
              this.collectionItem.uuid,
              this.collectionItem.type,
              this.collectionStore.collectionUuids()
            )
          : false;

        this.cdr.markForCheck();
      });
  }

  private checkIfItemInCollection(
    uuid: string,
    type: CollectionSection,
    collectionUuids: CollectionUuids
  ): boolean {
    switch (type) {
      case 'ARTICLE':
        return collectionUuids?.articleUuids?.includes(uuid);

      case 'BUNDLE':
        return collectionUuids?.bundleUuids?.includes(uuid);

      case 'PATHOLOGY':
        return collectionUuids?.pathologyUuids?.includes(uuid);

      case 'PATHOLOGY_TYPE':
        return collectionUuids?.pathologyTypeUuids?.includes(uuid);

      default:
        return false;
    }
  }
}
